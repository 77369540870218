import { Directive, forwardRef, OnInit } from '@angular/core';
import { ValidationErrors, ValidatorFn } from '@angular/forms';
import { InputComponent } from '@util/components/input/input.component';
import { BaseFormComponent } from '../../base-form.component';
import { BaseValidatorDirective } from '../base-validator.directive';


@Directive({
  selector: '[appInputWithinMinMaxValidator]',
  inputs: ['appInputWithinMinMaxValidator.useTranslationKey', 'appInputWithinMinMaxValidator.trigger'],
  exportAs: 'appInputWithinMinMaxValidator',
  providers: [
    {provide: BaseValidatorDirective, useExisting: forwardRef(() => InputWithinMinMaxValidatorDirective)},
    {provide: BaseFormComponent, useExisting: forwardRef(() => InputComponent)},
  ]
})
export class InputWithinMinMaxValidatorDirective extends BaseValidatorDirective implements OnInit {

  protected override _thisSelector = 'appInputWithinMinMaxValidator';
  protected override translationKey = 'validation.input.appInputWithinMinMaxValidator';

  override ngOnInit() {
    super.ngOnInit();
    this.updateTranslation();

    this.ngZone.run(() => {
      this.host?.formControl?.addValidators(this.validator);

      this.host?.formControl?.updateValueAndValidity();
      this.host?.formControl?.markAsPristine();
      this.host?.formControl?.markAsUntouched();
    });
  }

  validator: ValidatorFn = (control) => {
    const cmp = this.host as InputComponent;

    const valueStr = control.value;
    const num = parseFloat(valueStr);
    const isNum = !Number.isNaN(num);
    let isWithin = true;

    if (isNum) {

      let min = parseFloat(cmp.min);
      const includeMin = !Number.isNaN(min);
      if (!includeMin) {
        min = -Infinity;
      }
      let max = parseFloat(cmp.max);
      const includeMax = !Number.isNaN(max);
      if (!includeMax) {
        max = Infinity;
      }

      if (includeMin && !includeMax) {
        this.translationKey = 'validation.input.appInputWithinMinMaxValidator_onlyMin';
      }

      if (!includeMin && includeMax) {
        this.translationKey = 'validation.input.appInputWithinMinMaxValidator_onlyMax';
      }

      if (includeMin && includeMax) {
        if (min !== max) {
          this.translationKey = 'validation.input.appInputWithinMinMaxValidator';
        } else {
          this.translationKey = 'validation.input.appInputWithinMinMaxValidator_minMaxSameNum';
        }

      }

      isWithin = min <= num && num <= max;

      this.updateTranslation({min, max});
    }

    const errors: ValidationErrors = {'appInputWithinMinMaxValidator': this.translation};
    return isWithin ? null : errors;
  };

}
